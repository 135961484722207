import Layout from 'src/components/layout';
import DashboardItem from 'src/components/pages/dashboard/DashboardItem';
import PageHeader from 'src/components/pages/general/PageHeader';
import {
    ArchiveIcon,
    ClipboardCheckIcon,
    ClipboardCopyIcon,
    CurrencyDollarIcon,
    DatabaseIcon,
    RefreshIcon,
    TrendingUpIcon,
    UserGroupIcon,
} from '@heroicons/react/outline';
import DashboardChart from 'src/components/pages/dashboard/DashboardChart';
import useHashRoute from 'src/hooks/useHashRoute';
import { useEffect, useState } from 'react';
import {
    useFetchGlobalMetrics,
    useFetchMyCases,
} from '../src/services/useReferral';

const dashboardItemsData = [
    {
        icon: UserGroupIcon,
        title: `Total <br /> lawyers`,
        chartTitle: 'Total lawyers',
        hash: 'totalLawyers',
    },
    {
        icon: ClipboardCopyIcon,
        title: 'My Total Created Referrals ',
        hash: 'totalNewReferrals',
    },
    {
        icon: ClipboardCheckIcon,
        title: 'My Active Referrals',
        hash: 'totalActiveReferrals',
    },
    {
        icon: ArchiveIcon,
        title: 'My Total Closed referral',
        hash: 'totalClosedReferrals',
    },
    {
        icon: ClipboardCopyIcon,
        title: 'My Active Cases',
        hash: 'myActiveReferrals',
    },
    {
        icon: ClipboardCheckIcon,
        title: 'My Closed Cases',
        hash: 'myClosedReferrals',
    },

    // {
    //     icon: TrendingUpIcon,
    //     title: 'Total money earned',
    //     data: '124k CAD',
    //     hash: 'totalMoneyEarned',
    // },
    // {
    //     icon: RefreshIcon,
    //     title: 'Total money paid to others',
    //     data: '15,051 CAD',
    //     hash: 'totalMoneyPaidToOthers',
    // },
    // {
    //     icon: CurrencyDollarIcon,
    //     title: 'Balance now',
    //     data: '41k CAD',
    //     hash: 'balanceNow',
    // },
    // {
    //     icon: DatabaseIcon,
    //     title: 'Rewards balance now',
    //     data: '54k TKN',
    //     hash: 'rewardsBalanceNow',
    // },
];

const DashboardPage: React.FunctionComponent<{}> = () => {
    const hashRoute = useHashRoute();
    const [selectedData, setSelectedData] = useState(dashboardItemsData[0]);

    const [{ data, error }, loadGlobalMetrics, loading] =
        useFetchGlobalMetrics();

    useEffect(() => {
        loadGlobalMetrics();
    }, []);

    useEffect(() => {
        const foundData = dashboardItemsData.find(
            (item) => item.hash === hashRoute,
        );
        foundData && setSelectedData(foundData);
    }, [hashRoute]);

    return (
        <Layout>
            <PageHeader title="Dashboard" />
            <div className="grid gap-x-8 gap-y-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                {dashboardItemsData.map((item: any, key: number) => (
                    <DashboardItem
                        key={key}
                        data={{ ...item, value: data[item.hash] }}
                    />
                ))}
            </div>
            <br />
            {/*<DashboardChart*/}
            {/*    title={selectedData.chartTitle || selectedData.title}*/}
            {/*/>*/}
        </Layout>
    );
};

export default DashboardPage;
