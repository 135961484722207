import Link from 'next/link';
import parse from 'html-react-parser';

const DashboardItem = ({ data }: any) => {
    return (
        <div className="sm:max-w-[376px] rounded-xl shadow-md bg-white">
            <div className="flex p-5">
                <div className="icon bg-hoverBg rounded-md mr-5">
                    <data.icon className="w-[4.75rem] h-[3.75rem] p-5" />
                </div>
                <div className="flex flex-col justify-between">
                    <div className="title text-sm font-mont font-bold leading-4 text-[rgba(0,0,0,0.6)]">
                        {parse(data.title)}
                    </div>
                    <div className="data text-2xl font-mont font-bold leading-5">
                        {data.value}
                    </div>
                </div>
            </div>
            {/* PRD-541 hide according ticket */}
            {/* <Link href={`#${data.hash}`}>
                <div className="bg-hoverBg font-mont text-sm font-medium py-4 text-blue pl-[30px] cursor-pointer">
                    View all
                </div>
            </Link> */}
        </div>
    );
};

export default DashboardItem;
